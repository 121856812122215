.toccmap {
  background: url('/img/map.jpg');
  background-repeat: no-repeat;
  height: 1192px;
  width: 1800px;
  position: relative;
  margin: 0 auto;

  &:before {
    background-repeat: no-repeat;
    height: 1192px;
    width: 1800px;
    content: "";
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition-property: opacity;
    transition-duration: 0.5s;
    transition-timing-function: linear;
  }

  &--Christmas {
    &:before {
      background: url('/img/Christmas.png');
      opacity: 1;
    }
  }

  $heart-size: 150px;
  $heart-colour: #ce0404;

  &--Heart {
    .toccmap__inner {
      animation: heartbeat 1s infinite;
      background-color: $heart-colour;
      display: inline-block;
      height: $heart-size;
      margin: 0 10px;
      position: relative;
      top: 0;
      transform: rotate(-45deg);
      position: absolute;
      left: 55%;
      top: 45%;
      width: $heart-size;
      opacity: 0.9;
    }

    .toccmap__inner:before,
    .toccmap__inner:after {
      content: "";
      background-color: $heart-colour;
      border-radius: 50%;
      height: $heart-size;
      position: absolute;
      width: $heart-size;
    }

    .toccmap__inner:before {
      top: ($heart-size / 2) * -1;
      left: 0;
    }

    .toccmap__inner:after {
      left: ($heart-size / 2);
      top: 0;
    }
  }

  &.oc-loaded {
    .toccmap__item {
      opacity: 1;
    }
  }

  &__item {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.5s;
    transition-timing-function: linear;
    transition-delay: 0.5s;

    &:after {
      content: "";
      position: absolute;
      border: 5px solid #3e6d22;
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin: 0;
      background-color: white;
      color: #3e6d22;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    > i {
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: bold;
      font-size: 24px;
      z-index: 1;
    }

    &:hover {
      &:before {
        content: "";
        width: 100px;
        height: 100px;
        border: 2px solid white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(255, 255, 255, 0.5);
      }

      &:after {
        background-color: #3e6d22;
        border-color: white;
      }

      > i {
        color: white;
      }
    }
  }
}

body {
  background-color: #C7DDEB;
  margin: 0;
  padding: 0;
}

.oc-overlay__container--embed .oc-overlay__close {
  &:after {
    color: white;
  }
}

@keyframes heartbeat {
  0% {
    transform: rotate(-45deg) scale(1);
  }
  25% {
    transform: rotate(-45deg) scale(1.1);
  }
  40% {
    transform: rotate(-45deg) scale(1);
  }
  60% {
    transform: rotate(-45deg) scale(1.1);
  }
  100% {
    transform: rotate(-45deg) scale(1);
  }
}

@keyframes roll {
  from { transform: rotate(0deg) }
  to   { transform: rotate(360deg) }
}
body.barrel-roll {
  animation-name: roll;
  animation-duration: 4s;
  animation-iteration-count: 1;
}